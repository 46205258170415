import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';

import { useConfirmation } from '@hapvida/hapvida-core-components';

import BeneficiaryService, {
  ExportBeneficiariesStatusEnum,
} from '@services/BeneficiaryService';

import { toastifyApiErrors } from '@utils';
import { useAuth } from '@hooks';

import {
  EXPORT_BENEFICIARIES_STATE,
  GET_EXPORT_BENEFICIARIES_STATE,
} from './constants/queryKeys';

export function useExportBeneficiariesFile() {
  const { showConfirmation } = useConfirmation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { selectedCompany } = useAuth();
  const [isFetchingExportBeneficiaries, setIsFetchingExportBeneficiaries] =
    useState(false);
  const beneficiaryService = useMemo(() => new BeneficiaryService(), []);

  const companyId = selectedCompany?.id ?? '';

  const fetchExportBeneficiaries = async () => {
    setIsFetchingExportBeneficiaries(true);
    await queryClient.fetchQuery({
      queryKey: [EXPORT_BENEFICIARIES_STATE, companyId],
      cacheTime: 0,
      queryFn: () => beneficiaryService.exportBeneficiaries(),
    });
    setIsFetchingExportBeneficiaries(false);
  };

  const {
    isFetching,
    refetch,
    data: exportBeneficiariesData,
  } = useQuery({
    queryKey: [GET_EXPORT_BENEFICIARIES_STATE, companyId],
    queryFn: () => beneficiaryService.getExportBeneficiaries(),
    cacheTime: 0,
    onSuccess: success => {
      if (success.status === ExportBeneficiariesStatusEnum.ERROR) {
        toastifyApiErrors(success, enqueueSnackbar);
      }
    },
    onError: error => {
      toastifyApiErrors(error, enqueueSnackbar);
    },
  });

  const isDefaultState =
    exportBeneficiariesData?.status === ExportBeneficiariesStatusEnum.PROCESS &&
    !isFetching;

  const isExportingBeneficiaries =
    exportBeneficiariesData?.status ===
      ExportBeneficiariesStatusEnum.PROCESSING ||
    isFetching ||
    isFetchingExportBeneficiaries;

  const isReadyToDownload =
    exportBeneficiariesData?.status ===
      ExportBeneficiariesStatusEnum.PROCESSED && !isFetching;

  const handleDownloadBeneficiariesFile = () => {
    if (isReadyToDownload) {
      window.open(exportBeneficiariesData?.downloadLink, '_blank');
      queryClient.removeQueries({
        queryKey: [EXPORT_BENEFICIARIES_STATE],
      });
    }
  };

  const handleStartDownloadBeneficiariesFile = async () => {
    try {
      if (isDefaultState) await fetchExportBeneficiaries();

      refetch();
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
    }
  };

  const handleExportBeneficiariesFile = async () => {
    handleStartDownloadBeneficiariesFile();

    showConfirmation({
      title: 'Aguarde! Estamos exportando o seu arquivo.',
      confirmText: 'Fechar',
      type: 'default',
      content: (
        <Typography variant="text">
          Estamos exportando o seu arquivo e em breve tudo estará pronto para
          você.
        </Typography>
      ),
    });
  };

  return {
    handleExportBeneficiariesFile,
    handleDownloadBeneficiariesFile,
    isExportingBeneficiaries,
    isReadyToDownload,
    refetch,
    status: exportBeneficiariesData?.status,
    isFetching,
  };
}
